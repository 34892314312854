export default {
itensDescricaoPlano(descricao) {
    if (!descricao) {
      return [];
    }

    var itens = descricao.trim().split(";");

    itens = itens.filter(item => item.trim());
    return itens;
  }
}