<template>
  <div v-if="dados" class="anuncio text-center  shadow">
    <div class="row no-gutters mb-auto">
      <div class="col-7">
        <router-link
          :to="`/dashboard/anuncio/${dados.id}`"
        >
        <div
          v-if="dados.fotos && dados.fotos.length > 0"
          class="img-anuncio"
          :style="{backgroundImage: 'url('+dados.fotos[0]+')', backgroundColor: 'white'}"
        ></div>
        <div v-else class="img-anuncio sem-imagem"></div>
        </router-link>
      </div>
      <div class="col-5 d-flex flex-column ">
        <div class="flex-grow-1 d-flex align-items-center justify-content-center p-2">
          <div>
          <span
            v-if="dados.destaque"
            class="badge badge-pill badge-warning anuncio-destaque"
          >
          <div style="font-size:12px; margin-bottom:4px;">Anúncio destaque</div>
          <span v-if="dados.dataExpiracaoPlano">até {{dados.dataExpiracaoPlano | formatLocalDate}}</span>
          </span>
          <div class="marca">{{dados.marca}}</div>
          <div class="modelo">{{dados.modelo}} {{dados.ano}}</div>
          <div class="preco fonte-numero">{{dados.valorVenda | formatMoney}}</div>
          </div>
        </div>
        <b-dropdown variant="none" size="sm" class="border-top" right v-if="!esconderOpcoes">
          <template v-slot:button-content>
            <span class="font-weight-bold text-success">Opções</span>
          </template>

            <b-dropdown-item
            v-if="dados.status == statusAnuncio.STATUS_ANUNCIO_ATIVO && !dados.destaque"
            @click="onClickDestacarAnuncio"
          ><span class="texto-destaque">Destacar este anúncio</span> </b-dropdown-item>
           <b-dropdown-divider  v-if="dados.status == statusAnuncio.STATUS_ANUNCIO_ATIVO && !dados.destaque"></b-dropdown-divider>

          <b-dropdown-item
            v-if="dados.status == statusAnuncio.STATUS_ANUNCIO_NAO_FINALIZADO"
            :to="`/dashboard/anuncio/${dados.id}`"
          >Completar anúncio</b-dropdown-item>

           <b-dropdown-item
            v-if="dados.status != statusAnuncio.STATUS_ANUNCIO_NAO_FINALIZADO"
            :to="`/dashboard/anuncio/${dados.id}`"
          >Editar anúncio</b-dropdown-item>

          <b-dropdown-item
            v-if="dados.status == statusAnuncio.STATUS_ANUNCIO_ATIVO"
            :to="`/anuncio/${dados.id}`"
            target="_blank"
          >Ver anúncio na loja</b-dropdown-item>

        

          <b-dropdown-item
            v-if="dados.status == statusAnuncio.STATUS_ANUNCIO_ATIVO"
            @click="onClickDesativarAnuncio"
          >Desativar anúncio</b-dropdown-item>

          <b-dropdown-item
            v-if="dados.status == statusAnuncio.STATUS_ANUNCIO_DESATIVADO"
            @click="onClickAtivarAnuncio"
          >Ativar anúncio</b-dropdown-item>

          <b-dropdown-divider></b-dropdown-divider>

          <b-dropdown-item @click="onClickExcluirAnuncio">
            <span class="text-danger">Excluir anúncio</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>


    <!-- <div class="row no-gutters border-top mt-3"> -->
      <!-- <div class="col-7 text-center">
        <router-link
          class="btn btn-block btn-sm font-weight-bold text-success"
          :to="`/dashboard/anuncio/${dados.id}`"
        >
          <span v-if="dados.status == statusAnuncio.STATUS_ANUNCIO_NAO_FINALIZADO">Completar anúncio</span>
          <span v-else>Editar anúncio</span>
        </router-link>
      </div> -->
      <!-- <div class="col-5 text-center"> -->
        
      <!-- </div>
    </div> -->
  </div>
</template>

<script>
import statusAnuncio from "../../core/statusAnuncio";

export default {
  props: ["url", "dados", "esconderOpcoes"],
  data() {
    return {
      statusAnuncio: statusAnuncio
      // dados: {
      //   marca: "Caloi",
      //   modelo: "Elite 30",
      //   ano: 2019,
      //   valorVenda: 3000
      // }
    };
  },
  methods: {
    onClickVerAnuncio() {
      this.$emit("ver-anuncio", this.dados?.id);
    },
    onClickExcluirAnuncio() {
      this.$emit("excluir-anuncio", this.dados?.id);
    },
    onClickDesativarAnuncio() {
      this.$emit("desativar-anuncio", this.dados?.id);
    },
    onClickAtivarAnuncio() {
      this.$emit("ativar-anuncio", this.dados?.id);
    },
    onClickDestacarAnuncio() {
      this.$emit("destacar-anuncio", this.dados?.id);
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/variaveis";

.img-anuncio {
  // background-image: url("/img/empty_img.png");
  background-position: center center;
  background-size: cover;
  background-color: @light-gray-color;
  width: 100%;
  height: 200px;
  border-top-left-radius: @border-radius;
  border-bottom-left-radius: @border-radius;
  
}

.img-anuncio.sem-imagem {
  background-image: url("/img/empty_img2.jpg");
}

.anuncio {
  // width:234px;
  background-color: white;
  border-radius: @border-radius;
  // box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.11);
  height: 100%;
}

.informacoes {
  padding: 12px;
  padding-bottom: 0;
  padding-top: 8px;
}
.marca {
  color: #858585;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
}

.modelo {
  color: @default-text-color;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
}

.preco {
  color: @primary-color;
  font-weight: 700;
  font-size: 18px;
  // font-size: 24px;
}

.data-anuncio {
  line-height: 12px;
  padding-left: 12px;
}

.btn-anuncio {
  background-color: @primary-color;
  color: white;
  font-weight: 700;
  font-size: 14px;
  // height: 43px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: @border-radius;
  border-bottom-right-radius: @border-radius;
}

.btn-anuncio:hover {
  color: white;
  opacity: 0.9;
}

@media (max-width: 576px) {
  // .img-anuncio {
  //   border-top-right-radius: 0;
  // }

  .marca {
    font-size: 12px;
  }

  .modelo {
    font-size: 16px;
  }

  .preco {
    font-size: 18px;
  }

  // .regiao{
  //   font-size: 12px;
  // }

  .btn-anuncio {
    height: auto;
  }
}
</style>