<template>
  <b-modal
    id="modal-desativar-anuncio"
    title="Desativar anúncio?"
    size="md"
    variant="dark"
    @shown="abriuModal"
  >
    <p>Anúncios desativados não são mais exibidos na loja. Outras pessoas não poderão ver ou comprar o produto.</p>
    <hr />
    <div class="form-group">
    <label>Poderia nos dizer o motivo da desativação?</label>
    <select class="form-control" v-model="motivo">
      <option :value="undefined">Selecione...</option>
      <option value="finalizando_negociacao">Estou aguardando finalização de negociação</option>
      <option value="vendido_pela_plataforma">Já vendi aqui pelo Bike Registrada</option>
      <option value="vendido_outra_plataforma">Já vendi por outra plataforma</option>
      <option value="vendido_pessoalmente">Já vendi sem auxilio de alguma plataforma</option>
      <option value="outros">Outros</option>
    </select>
    </div>

<div class="form-group">
    <label >Algum comentário ou sugestão para nós?</label>
    <textarea class="form-control" v-model="sugestao"></textarea>
    </div>

    <template v-slot:modal-footer="{cancel}">
      
      <div class="row mx-0 w-100">
       
        <div class="col-6">
          <button
            class="btn btn-danger btn-block"
            :disabled="loadingDesativar"
            @click="onClickDesativar"
          >
            <span v-if="loadingDesativar">Desativando...</span>
            <span v-else>Desativar</span>
          </button>
        </div>
         <div class="col-6">
          <button class="btn btn-secondary btn-block" @click="cancel">Cancelar</button>
        </div>
    
      </div>
    </template>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import config from "../../config";
import captureError from "../../helpers/captureError";

export default {
  props: ["idAnuncio"],
  data() {
    return {
      loadingDesativar: false,

      motivo: undefined,
      sugestao: undefined
    };
  },
  methods: {
    async onClickDesativar() {

      this.loadingDesativar = true;

      var result = await this.desativarAnuncio(this.idAnuncio);

      this.loadingDesativar = false;

      if (result) {
        this.$emit("desativado");
      }

      //Fecha a modal
      this.$bvModal.hide("modal-desativar-anuncio");
    },
    abriuModal() {
      this.motivo = undefined;
      this.sugestao = undefined;
    },

    async desativarAnuncio(idAnuncio) {
      try {
          var data = {
              idAnuncio: idAnuncio,
              motivo: this.motivo,
              sugestao: this.sugestao
          };
        await axios.put(`${config.API_URL}/anuncio/desativar`, data);

        Swal.fire({
          title: "Anúncio desativado com sucesso.",
          icon: "success"
        });

        return true;
      } catch (e) {
        captureError(e);
        //console.error(e);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível desativar o anúncio. Tente novamente mais tarde ou contate o suporte."
        });

        return false;
      }
    }
  }
};
</script>

<style scoped>
input {
  text-transform: uppercase;
  text-align: center;
  font-size: 1.5rem;
}
</style>
