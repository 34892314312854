<template>
  <div class="container meus-anuncios-container">
    <div class="text-left py-3">
      <div class="row">
        <div class="col-6">
          <h2>Meus anúncios</h2>
        </div>
        <div class="col-6 text-right">
          <router-link
            v-if="anuncios.length > 0"
            to="/dashboard/anuncio"
            class="btn btn-primary"
          >Novo anúncio</router-link>
        </div>
      </div>
    </div>

    <div v-if="loadingAnuncios" class="p-5">Carregando...</div>

    <div v-if="!loadingAnuncios && anuncios.length == 0">
      <img src="/img/iconeNovoAnuncio.png" height="160" />
      <h5>Você ainda não possui nenhum anúncio.</h5>

      <div>Crie um agora mesmo!</div>
      <router-link to="/dashboard/anuncio" class="btn btn-primary px-5 mt-3">Novo anúncio</router-link>
    </div>
    <div v-if="!loadingAnuncios && anuncios.length > 0">
      <div
        v-for="grupoStatus in getAnunciosAgrupadosPorStatus()"
        :key="grupoStatus.status"
        class="mb-3"
      >
        <h4
          class="text-left"
        >{{statusAnuncio.getDescricaoStatus(grupoStatus.status, true)}} ({{grupoStatus.items.length}})</h4>
        <div class="row small-gutters">
          <div
            v-for="(anuncio,index) in grupoStatus.items"
            :key="'anuncio'+index"
            class="col-12 col-sm-6 mb-3"
          >
            <BlocoMeuAnuncio
              :dados="anuncio"
              @excluir-anuncio="onClickExcluirAnuncio"
              @desativar-anuncio="onClickDesativarAnuncio"
              @ativar-anuncio="onClickAtivarAnuncio"
              @destacar-anuncio="onClickDestacarAnuncio"
            />
          </div>
        </div>
      </div>

      <!-- <h4>Não finalizados</h4>
      <h4>Ativos</h4>
      <h4>Desativados</h4>
      <h4>Expirados</h4>-->
    </div>
    <ModalDesativarAnuncio :idAnuncio="idAnuncioSelecionado" @desativado="onDesativarAnuncio" />
    <ModalDestacarAnuncio :idAnuncio="idAnuncioSelecionado" />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";

import captureError from "../../../helpers/captureError";
import config from "../../../config";
import statusAnuncio from "../../../core/statusAnuncio";
import BlocoMeuAnuncio from "../../../components/Dashboard/BlocoMeuAnuncio.vue";
import ModalDesativarAnuncio from "../../../components/Dashboard/ModalDesativarAnuncio.vue";
import ModalDestacarAnuncio from "../../../components/Dashboard/ModalDestacarAnuncio.vue";

export default {
  components: {
    BlocoMeuAnuncio,
    ModalDesativarAnuncio,
    ModalDestacarAnuncio
  },
  data() {
    return {
      statusAnuncio: statusAnuncio,

      anuncios: [],
      loadingAnuncios: false,
      idAnuncioSelecionado: undefined
    };
  },
  created() {
    this.popularAnuncios();
  },
  methods: {
    async popularAnuncios() {
      try {
        this.loadingAnuncios = true;

        var response = await axios.get(`${config.API_URL}/anuncio/porCliente`);

        this.anuncios = response.data;
        this.loadingAnuncios = false;

        this.getAnunciosAgrupadosPorStatus();
      } catch (e) {
        captureError(e);
        this.loadingAnuncios = false;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível carregar os anúncios. Tente novamente mais tarde ou contate o suporte."
        });
      }
    },
    getAnunciosAgrupadosPorStatus() {
      var grupos = {};

      for (let index = 0; index < this.anuncios.length; index++) {
        const anuncio = this.anuncios[index];
        var chave = anuncio.status;
        var grupo = grupos[chave];

        if (!grupo) {
          grupo = { status: chave, items: [] };
          grupos[chave] = grupo;
        }

        grupo.items.push(anuncio);
      }

      //Colocando os não finalizados como primeira opção
      var naoFinalizados = grupos[3];
      if (naoFinalizados) {
        delete grupos[3];
      }

      var gruposArr = Object.values(grupos);

      if (naoFinalizados) {
        gruposArr = [naoFinalizados].concat(gruposArr);
      }

      return gruposArr;
    },
    onClickExcluirAnuncio(idAnuncio) {
      Swal.fire({
        title: "Tem certeza?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        allowOutsideClick: () => !Swal.isLoading(),
        preConfirm: async () => {
          Swal.showLoading();
          await this.excluirAnuncio(idAnuncio);
        }
      });
    },
    onClickAtivarAnuncio(idAnuncio) {
      Swal.fire({
        title: "Tem certeza que deseja ativar?",
        text:
          "Anúncios ativados são exibidos na loja para que outras pessoas possam ver ou comprar.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        allowOutsideClick: () => !Swal.isLoading(),
        preConfirm: async () => {
          Swal.showLoading();
          await this.ativarAnuncio(idAnuncio);
        }
      });
    },
    onClickDesativarAnuncio(idAnuncio) {
      this.idAnuncioSelecionado = idAnuncio;
      this.$bvModal.show("modal-desativar-anuncio");
      // Swal.fire({
      //   title: "Tem certeza que deseja desativar?",
      //   text:
      //     "Anuncios desativados não são mais exibidos na loja. Outras pessoas não poderão ver ou comprar o anúncio.",
      //   icon: "warning",
      //   showCancelButton: true,
      //   confirmButtonColor: "#d33",
      //   confirmButtonText: "Sim",
      //   cancelButtonText: "Não",
      //   allowOutsideClick: () => !Swal.isLoading(),
      //   preConfirm: async () => {
      //     Swal.showLoading();
      //     await this.desativarAnuncio(idAnuncio);
      //   }
      // });
    },
    onClickDestacarAnuncio(idAnuncio) {
      this.idAnuncioSelecionado = idAnuncio;
      this.$bvModal.show("modal-destacar-anuncio");
    },
    async excluirAnuncio(idAnuncio) {
      try {
        await axios.delete(`${config.API_URL}/anuncio/${idAnuncio}`);

        Swal.fire({
          title: "Excluido com sucesso",
          icon: "success"
        });

        this.popularAnuncios();
      } catch (e) {
        captureError(e);
        //console.error(e);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível excluir o anúncio. Tente novamente mais tarde ou contate o suporte."
        });
      }
    },
    async ativarAnuncio(idAnuncio) {
      try {
        var data = {
          idAnuncio: idAnuncio
        };
        await axios.put(`${config.API_URL}/anuncio/ativar`, data);

        Swal.fire({
          title: "Anúncio ativado com sucesso.",
          icon: "success"
        });

        this.popularAnuncios();
      } catch (e) {
        captureError(e);
        //console.error(e);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível ativar o anúncio. Tente novamente mais tarde ou contate o suporte."
        });
      }
    },
    async desativarAnuncio(idAnuncio) {
      try {
        await axios.put(`${config.API_URL}/anuncio/desativar/${idAnuncio}`);

        Swal.fire({
          title: "Anúncio desativado com sucesso.",
          icon: "success"
        });

        this.popularAnuncios();
      } catch (e) {
        captureError(e);
        //console.error(e);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível desativar o anúncio. Tente novamente mais tarde ou contate o suporte."
        });
      }
    },
    onDesativarAnuncio() {
      this.popularAnuncios();
    }
  }
};
</script>

<style lang="less" scoped>
.meus-anuncios-container {
  // background-color:red;
  max-width: 1100px;
}
</style>