<template>
  <b-modal
    id="modal-destacar-anuncio"
    title="Destaque seu anúncio!"
    size="md"
    variant="dark"
    hide-footer
    @shown="abriuModal"
  >
    <div v-if="loadingGeral" class="p-5">Carregando...</div>
    <div v-else>
      <div class="text-center">
        <img src="/img/IconeDestaque.png" width="250" />
      </div>
      <p>Tenha mais visibilidade para inpulsionar suas negociações.</p>

      <ul>
        <li
          v-for="(item, index) in planoAnuncioCore.itensDescricaoPlano(
            planoAnuncio.descricao
          )"
          :key="'p' + index"
        >
          {{ item }}
        </li>
      </ul>

      <div class="text-center pb-2">
        <h5 class="font-weight-normal">Tudo isso por apenas</h5>
        <h2>{{ planoAnuncio.valor | formatMoney }}</h2>
      </div>
      <hr />
      <router-link
        :to="`/dashboard/checkout-plano/${idAnuncio}/${config.PLANO_DESTAQUE_ID}`"
        class="btn btn-secondary btn-block"
        >Quero destacar meu anúncio</router-link
      >
    </div>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import config from "../../config";
import captureError from "../../helpers/captureError";
import planoAnuncioCore from "../../core/planoAnuncio";

export default {
  props: ["idAnuncio"],
  data() {
    return {
      config,
      planoAnuncioCore,

      loadingGeral: true,
      planoAnuncio: {},
    };
  },
  methods: {
    abriuModal() {
      this.popularPlanoDestaque();
    },

    async popularPlanoDestaque() {
      try {
        this.loadingGeral = true;

        var response = await axios.get(
          `${config.API_URL}/planoanuncio/${config.PLANO_DESTAQUE_ID}`
        );
        this.planoAnuncio = response.data;

        this.loadingGeral = false;
      } catch (e) {
        captureError(e);
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível carrega o plano de anúncio. Tente novamente mais tarde ou contate o suporte.",
        });

        this.$bvModal.hide("modal-destacar-anuncio");
      }
    },
  },
};
</script>

<style scoped>
input {
  text-transform: uppercase;
  text-align: center;
  font-size: 1.5rem;
}
</style>
