export default {

    STATUS_ANUNCIO_ATIVO: 1,
    STATUS_ANUNCIO_DESATIVADO: 2,
    STATUS_ANUNCIO_NAO_FINALIZADO: 3,
    STATUS_ANUNCIO_EXPIRADO: 4,

    getDescricaoStatus(status, plural) {

        if (status == this.STATUS_ANUNCIO_ATIVO) {
            return plural ? "Ativos" : "Ativo";

        } else if (status == this.STATUS_ANUNCIO_DESATIVADO) {
            return plural ? "Desativados" : "Desativado";

        } else if (status == this.STATUS_ANUNCIO_NAO_FINALIZADO) {
            return plural ? "Não finalizados" : "Não finalizado";

        } else if (status == this.STATUS_ANUNCIO_EXPIRADO) {
            return plural ? "Expirados" : "Expirado";
        } else {
            return plural ? "Não definidos" : "Não definido";
        }
    }
}